import styled from "styled-components";

export const PublicationsContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PublicationsWrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  align-self: center;
`;

export const PubliSectionTitle = styled.h3`
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 30px;
  margin-bottom: 20px;
  display: ${({ visible }) => (visible ? "" : "none")};

  &:before {
    content: "";
    display: inline-block;
    margin-right: 1%;
    width: 100px;
    height: 10px;
    background-color: white;
  }
`;

export const TagListWrapper = styled.div`
  max-width: 1100px;
  width: 100%;
  align-self: center;
  margin-bottom: 50px;
`;
