import React, { useState } from "react";
import NavbarWeb from "./NavbarWeb";
import Sidebar from "./Sidebar";

const Navbar = ({ french, changeFr }) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <Sidebar
        isOpen={isOpen}
        toggle={toggle}
        french={french}
        changeFr={changeFr}
      />
      <NavbarWeb toggle={toggle} french={french} changeFr={changeFr} />
    </>
  );
};

export default Navbar;
