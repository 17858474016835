import React from "react";
import { ComposableMap, Geographies, Marker } from "react-simple-maps";
import { StyledGeography, StyledMarker, InfoBulle } from "./MapElements";
import { MdPlace } from "react-icons/md";
import { markers, talks } from "../data";

const Map = ({ french }) => {
  const geoUrl = "map/europe.json";

  return (
    <ComposableMap
      projection="geoAzimuthalEqualArea"
      projectionConfig={{
        rotate: [-20, -50, 10],
        scale: 400,
      }}
      height="200"
      width="500"
    >
      <Geographies geography={geoUrl}>
        {({ geographies }) =>
          geographies.map((geo) => (
            <StyledGeography key={geo.rsmKey} geography={geo} />
          ))
        }
      </Geographies>
      {markers.map((marker) => (
        <Marker
          key={marker["key"]}
          coordinates={marker["coordinates"]}
          color="black"
        >
          <MdPlace y="-14" x="-7.5" fontSize={15} />
        </Marker>
      ))}
      {markers.map((marker) => (
        <StyledMarker key={marker["key"]} coordinates={marker["coordinates"]}>
          <MdPlace y="-14" x="-7.5" fontSize={15} />
          <defs>
            <filter x="-5%" y="-15%" width="110%" height="130%" id="solid">
              <feFlood flood-color="black" result="bg" />
              <feMerge>
                <feMergeNode in="bg" />
                <feMergeNode in="SourceGraphic" />
              </feMerge>
            </filter>
          </defs>
          <InfoBulle filter="url(#solid)">
            <tspan x="0" dy="1.2em" font-weight="bold">
              {marker[french ? "fr" : "en"]["nom"]}
            </tspan>
            <tspan x="0" dy="1.2em">
              {" "}
            </tspan>
            {talks.map(
              (t) =>
                t["marker"] === marker["key"] && (
                  <tspan x="0" dy="1.2em">
                    {t[french ? "fr" : "en"]["date"]}
                    {", "}
                    {t[french ? "fr" : "en"]["title"]}
                  </tspan>
                )
            )}
          </InfoBulle>
        </StyledMarker>
      ))}
    </ComposableMap>
  );
};

export default Map;
