import React, { useState, useEffect } from "react";
// import { MainTitle } from "../TitleSection";
import getElementOffset from "../react-scrolling-color-background/getElementOffset";
import {
  AProposContainer,
  Row,
  Column1,
  Column2,
  Paragraphe,
  PhotoWeb,
  PhotoMobile,
  SubjectTitle,
  Column3,
  Column4,
  SubjectWrapper,
  Description,
  Row2,
  AProposWrapper,
  AProposSubtitle,
  AProposSubtitleWrapper,
  Shape,
  InnerShape,
  FeaturesWrapper,
} from "./AProposElements";
import { MainTitle } from "../TitleSection";
import photo from "../../images/photo3.jpeg";
import { translations } from "./data";

const APropos = ({ french }) => {
  const [scrollYimg, setScrollYimg] = useState(0);
  const [imgOffset, setImgOffset] = useState(0);
  const [subjectOver, setSubjectOver] = useState(0);

  const subjects = [
    { id: 0, title: "Représentation des groupes p-adiques" },
    { id: 1, title: "Programme de Langlands" },
    { id: 2, title: "Immeuble de Bruhat-Tits" },
    { id: 3, title: "l-blocs" },
    { id: 4, title: "Représentation des groupes finis" },
  ];

  const subjectsDescription = [
    {
      id: 0,
      description:
        "Représentation des groupes p-adiques qsdf qsdkf qsfd qsfd qsdf qsdflk nqsldf",
    },
    {
      id: 1,
      description: "Programme de Langlands coucou coucouc ouc ouc coucoucouc",
    },
    {
      id: 2,
      description: " Immeuble de Bruhat-Tits coucou coucouc ouc ouc coucoucouc",
    },
    { id: 3, description: "l-blocs coucou coucouc ouc ouc coucoucouc" },
    {
      id: 4,
      description:
        "Représentation des groupes finis coucou coucouc ouc ouc coucoucouc",
    },
  ];

  const handleScroll = () => {
    setScrollYimg(window.scrollY);

    const subjectOffset = [
      ...document.querySelectorAll(".subjectWrapperClass"),
    ].map((el) => {
      return getElementOffset(el).top;
    });

    const index = subjectOffset.length;
    let n = index;
    while (n >= 0) {
      if (subjectOffset[n] - window.scrollY < (window.innerHeight * 2) / 3) {
        setSubjectOver(n);
        n = -1;
      }
      n--;
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    setImgOffset(
      getElementOffset(document.getElementById("aproposContainer")).top
    );
  }, []);

  const onMouseSubject = (sub) => {
    setSubjectOver(sub);
  };

  return (
    <AProposContainer id="aproposContainer">
      <AProposWrapper>
        <Row>
          <Column1>
            <MainTitle>
              {" "}
              {translations[french ? "fr" : "en"]["title"]}
            </MainTitle>
            {/* <MainTitle> {translations[french ? "fr" : "en"]["title2"]}</MainTitle> */}
            <Paragraphe>
              {translations[french ? "fr" : "en"]["description"]}
            </Paragraphe>
          </Column1>
          <Column2>
            <PhotoWeb
              src={photo}
              alt="photoLanard"
              width="400"
              style={{
                transform: `translateY(${-0.2 * (scrollYimg - imgOffset)}px)`,
              }}
            />
            <PhotoMobile src={photo} alt="photoLanard" width="300" />
          </Column2>
        </Row>
      </AProposWrapper>

      {/* <AProposSubtitleWrapper>
      <AProposSubtitle>
        Mes intérêts de recherche
      </AProposSubtitle>
      </AProposSubtitleWrapper> */}

      {/* <AProposWrapper>
        <FeaturesWrapper >
        <Shape>
          <InnerShape>
            
          </InnerShape>
        </Shape>
        <Shape>
          <InnerShape>
            
          </InnerShape>
        </Shape>
        <Shape>
          <InnerShape>
            
          </InnerShape>
        </Shape>
        <Shape>
          <InnerShape>
            
          </InnerShape>
        </Shape>
        </FeaturesWrapper>
      </AProposWrapper>

      <AProposWrapper> */}

      {/* <Row2>
        <Column3>
          <SubjectWrapper id="subjectWrapper">
            {subjects.map((s) => (
              <React.Fragment key={s.id}>
                <SubjectTitle
                  className="subjectWrapperClass link"
                  onMouseEnter={() => onMouseSubject(s.id)}
                  isActive={s.id === subjectOver}
                >
                  {s.title}
                </SubjectTitle>
                <br />
              </React.Fragment>
            ))}
          </SubjectWrapper>
        </Column3>

        <Column4>
          <Description>
            {subjectsDescription[subjectOver]["description"]}
          </Description>
        </Column4>
      </Row2>
      </AProposWrapper> */}
    </AProposContainer>
  );
};

export default APropos;
