import React, { useState, useEffect } from "react";
import lottie from "lottie-web/build/player/lottie_light";
import {
  HeroContainer,
  HeroTitle,
  HeroImagesWrapper,
  HeroBackgroundImage1,
  HeroBackgroundImage2,
  BtnScrollLink,
  BtnScrollWrapper,
  TitleLink,
} from "./HeroElements";

import lottieScroll from "../../images/lottie-scroll.json";

const Hero = () => {
  React.useEffect(() => {
    lottie.loadAnimation({
      container: document.querySelector("#lottie-scroll"),
      animationData: lottieScroll,
    });
  }, []);

  const [scrollLottie, setScrollLottie] = useState(false);
  const [scrollYimg, setScrollYimg] = useState(0);
  const [cursorX, setCursorX] = useState(0);
  const [cursorY, setCursorY] = useState(0);

  const handleScroll = () => {
    const wY = window.scrollY;

    if (wY > 50) {
      setScrollLottie(true);
    } else {
      setScrollLottie(false);
    }
    setScrollYimg(wY);
  };

  const handleMouseMove = (ev) => {
    setCursorX(ev.pageX);
    setCursorY(ev.pageY);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <HeroContainer onMouseMove={(ev) => handleMouseMove(ev)}>
      <HeroImagesWrapper>
        <HeroBackgroundImage1
          style={{
            filter: `blur(${0.025 * scrollYimg}px)`,
            transform:
              scrollYimg < 200
                ? `translateY(${
                    0.3 * scrollYimg +
                    (window.innerWidth / 2 - cursorY) * 0.04 -
                    30
                  }px) translateX(${
                    (window.innerHeight / 2 - cursorX) * 0.04
                  }px)`
                : `translateY(${
                    0.3 * 200 + (window.innerWidth / 2 - cursorY) * 0.04 - 30
                  }px) translateX(${
                    (window.innerHeight / 2 - cursorX) * 0.04
                  }px)`,
          }}
        />
        <HeroBackgroundImage2
          style={{
            filter: `blur(${0.02 * scrollYimg}px)`,
            transform:
              scrollYimg < 200
                ? `translateY(${
                    0.2 * scrollYimg +
                    (window.innerWidth / 2 - cursorY) * 0.07 -
                    30
                  }px) translateX(${
                    (window.innerHeight / 2 - cursorX) * 0.07
                  }px)`
                : `translateY(${
                    0.2 * 200 + (window.innerWidth / 2 - cursorY) * 0.07 - 30
                  }px) translateX(${
                    (window.innerHeight / 2 - cursorX) * 0.07
                  }px)`,
          }}
        />
        <HeroTitle
          style={{ transform: `translateY(${0.4 * scrollYimg - 40}px)` }}
        >
          <TitleLink
            to="apropos"
            smooth={true}
            duration={700}
            exact="true"
            offset={-60}
          >
            Thomas Lanard
          </TitleLink>
        </HeroTitle>
      </HeroImagesWrapper>

      <BtnScrollWrapper scrollLottie={scrollLottie}>
        <BtnScrollLink
          to="apropos"
          smooth={true}
          duration={700}
          exact="true"
          offset={-60}
          id="lottie-scroll"
        >
          {/* <BtnScrollLink id="lottie-scroll"> */}
          {/* <div id="react-logo" /> */}
        </BtnScrollLink>
      </BtnScrollWrapper>
    </HeroContainer>
  );
};

export default Hero;
