export const translations = {
  fr: {
    title: "Bonjour, je suis Thomas Lanard",
    description:
      "Je suis chargé de recherche au CNRS. Je travaille au laboratoire de Mathématiques de Versailles (LMV), UVSQ. Je m'intéresse à la théorie des nombres et aux représentations de groupes.",
  },
  en: {
    title: "Hello, I am Thomas Lanard",
    description: "I am a CNRS Research Fellow. I work at the mathematics Laboratory of Versailles, UVSQ. I am interested in number theory and representation theory.",
  },
};
