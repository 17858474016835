import styled from "styled-components";

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
`;

export const Row = styled.div`
  display: grid;
  grid-auto-columns: 1fr;
  align-items: center;
  grid-template-areas: "col2 col1";

  @media screen and (max-width: 768px) {
    grid-template-areas: "col1" "col2";
  }
`;

export const Column1 = styled.div`
  grid-area: col1;
`;

export const Column2 = styled.div`
  grid-area: col2;
  padding-right: 50px;
  padding-bottom: 50px;
  justify-self: right;
`;

export const ListIcon = styled.div`
  display: flex;
  flex-direction: row;
  font-size: 18px;
  margin-bottom: 20px;
`;

export const Icon = styled.div`
  font-size: 35px;
  margin-right: 15px;
`;

export const SousTitre = styled.h3`
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  margin-bottom: 5px;
`;

export const Mail = styled.div`
  a:focus,
  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
    color: white;
  }

  a:hover {
    text-decoration: underline;
  }
`;
