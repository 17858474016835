import "./App.css";
import ScrollingColorBackground from "./components/react-scrolling-color-background";
import Navbar from "./components/Navbar";
import { useState } from "react";
import { ColorSlide } from "./components/ColorSlide";
import Hero from "./components/Hero";
import APropos from "./components/APropos";
import Publications from "./components/Publications";
import CV from "./components/CV";
import Exposes from "./components/Exposes";
import Contact from "./components/Contact";
import AnimatedCursor from "./components/react-animated-cursor";
import { MathJaxContext } from "better-react-mathjax";

function App() {
  const [french, setFrench] = useState(true);

  const changeFr = () => {
    setFrench(!french);
  };

  const config = {
    loader: { load: ["[tex]/html"] },
    tex: {
      packages: { "[+]": ["html"] },
      inlineMath: [
        ["$", "$"],
        ["\\(", "\\)"],
      ],
      displayMath: [
        ["$$", "$$"],
        ["\\[", "\\]"],
      ],
    },
  };

  return (
    <>
      <AnimatedCursor size={15} scale={5} />

      <ScrollingColorBackground
        selector=".js-color-stop[data-background-color]"
        colorDataAttribute="data-background-color"
        initialRgb="rgb(17, 17, 40)"
        style={{
          position: "fixed",
          top: "0px",
          left: "0px",
          bottom: "0px",
          right: "0px",
          zIndex: "-1",
        }}
      />
      <Navbar french={french} changeFr={changeFr} />

      <ColorSlide data-background-color="rgb(17, 17, 40)">
        <Hero />
      </ColorSlide>
      <ColorSlide data-background-color="#2776AE" id="apropos">
        <APropos french={french} />
      </ColorSlide>
      <ColorSlide data-background-color="rgb(192, 57, 43)" id="publications">
        <MathJaxContext version={3} config={config}>
          <Publications french={french} />
        </MathJaxContext>
      </ColorSlide>
      {/* <ColorSlide data-background-color="rgb(220, 91, 4)" id="exposes"></ColorSlide> */}
      <ColorSlide data-background-color="rgb(221, 105, 111)" id="exposes">
        <Exposes french={french} />
      </ColorSlide>
      <ColorSlide data-background-color="rgb(35, 155, 86)" id="cv">
        <CV french={french} />
      </ColorSlide>
      <ColorSlide data-background-color="rgb(127, 106, 147)" id="contact">
        <Contact french={french} />
      </ColorSlide>
    </>
  );
}

export default App;
