export const translations = {
  fr: {
    title: "Mes exposés",
    intromoins: "Mes 10 derniers exposés",
    introplus: "Tout mes exposés",
    voirplus: "voir plus",
    voirmoins: "voir moins",
  },
  en: {
    title: "My talks",
    intromoins: "My last 10 talks",
    introplus: "All my talks",
    voirplus: "see more",
    voirmoins: "see less",
  },
};

export const markers = [
  {
    key: 12,
    coordinates: [3.0, 50.6],
    fr: { nom: "Lille, France" },
    en: { nom: "Lille, France" },
  },
  {
    key: 11,
    coordinates: [-1.7, 48.1],
    fr: { nom: "Rennes, France" },
    en: { nom: "Rennes, France" },
  },
  {
    key: 10,
    coordinates: [1.22, 52.64],
    fr: { nom: "Norwich, Royaume-Uni" },
    en: { nom: "Norwich, United-Kingdom" },
  },
  {
    key: 9,
    coordinates: [2.21, 49.9],
    fr: { nom: "Amiens, France" },
    en: { nom: "Amiens, France" },
  },
  {
    key: 8,
    coordinates: [6.14, 48.69],
    fr: { nom: "Nancy, France" },
    en: { nom: "Nancy, France" },
  },
  {
    key: 7,
    coordinates: [0.3, 46.58],
    fr: { nom: "Poitiers, France" },
    en: { nom: "Poitiers, France" },
  },
  {
    key: 6,
    coordinates: [15.8, 48.22],
    fr: { nom: "Vienne, Autriche" },
    en: { nom: "Vienna, Austria" },
  },
  {
    key: 5,
    coordinates: [-0.38, 51.53],
    fr: { nom: "Londres, Royaume-Uni" },
    en: { nom: "London, United Kingdom" },
  },
  {
    key: 4,
    coordinates: [4.76, 45.76],
    fr: { nom: "Lyon, France" },
    en: { nom: "Lyon, France" },
  },
  {
    key: 3,
    coordinates: [5.64, 45.18],
    fr: { nom: "Grenoble, France" },
    en: { nom: "Grenoble, France" },
  },
  {
    key: 2,
    coordinates: [2.28, 48.86],
    fr: { nom: "Île-de-France, France" },
    en: { nom: "Île-de-France, France" },
  },
  {
    key: 1,
    coordinates: [5.24, 43.28],
    fr: { nom: "Marseille, France" },
    en: { nom: "Marseille, France" },
  },
];

export const talks = [
  {
    id: 27,
    marker: 12,
    fr: {
      date: "Février 2024",
      title: "Séminaire Arithmétique",
      lieu: "Lille, France",
    },
    en: {
      date: "February 2024 2023",
      title: "Seminar Arithmetic",
      lieu: "Lille, France",
    },
  },
  {
    id: 26,
    marker: 2,
    fr: {
      date: "Décembre 2023",
      title: "Séminaire du Centre de Mathématiques Laurent Schwartz",
      lieu: "Palaiseau, France",
    },
    en: {
      date: "December 2023",
      title: "Seminar of Centre de Mathématiques Laurent Schwartz",
      lieu: "Palaiseau, France",
    },
  },
  {
    id: 25,
    marker: 7,
    fr: {
      date: "Novembre 2023",
      title: "Journées immeubles et applications",
      lieu: "Poitiers, France",
    },
    en: {
      date: "November 2023",
      title: "Journées immeubles et applications",
      lieu: "Poitiers, France",
    },
  },
  {
    id: 24,
    marker: 0,
    fr: {
      date: "Novembre 2023",
      title: "Beihang University",
      lieu: "Beijing, Chine",
    },
    en: {
      date: "November 2023",
      title: "Beihang University",
      lieu: "Beijing, China",
    },
  },
  {
    id: 23,
    marker: 9,
    fr: {
      date: "Septembre 2023",
      title: "Representations of reductive p-adic groups, L-functions and relative matters : a conference in honor of Guy Henniart",
      lieu: "Amiens, France",
    },
    en: {
      date: "September 2023",
      title: "Representations of reductive p-adic groups, L-functions and relative matters : a conference in honor of Guy Henniart",
      lieu: "Amiens, France",
    },
  },
  {
    id: 22,
    marker: 11,
    fr: {
      date: "Août 2023",
      title: "Journées d’Etudes 2023 du GDR Théorie de Lie Algébrique et Géométrique",
      lieu: "Rennes, France",
    },
    en: {
      date: "August 2023",
      title: "Journées d’Etudes 2023 du GDR Théorie de Lie Algébrique et Géométrique",
      lieu: "Rennes, France",
    },
  },
  {
    id: 21,
    marker: 0,
    fr: {
      date: "Avril 2023",
      title: "Université de Kyoto",
      lieu: "Kyoto, Japon",
    },
    en: {
      date: "April 2023",
      title: "Kyoto University",
      lieu: "Kyoto, Japan",
    },
  },
  {
    id: 20,
    marker: 9,
    fr: {
      date: "Janvier 2023",
      title: "Séminaire Groupes, Algèbre, Topologie",
      lieu: "Amiens, France",
    },
    en: {
      date: "January 2023",
      title: "Seminar Groupes, Algèbre, Topologie",
      lieu: "Amiens, France",
    },
  },
  {
    id: 19,
    marker: 1,
    fr: {
      date: "Novembre 2022",
      title: "Séminaire Représentations des Groupes Réductifs",
      lieu: "Marseille, France",
    },
    en: {
      date: "November 2022",
      title: "Seminar Représentations des Groupes Réductifs",
      lieu: "Marseille, France",
    },
  },
  {
    id: 18,
    marker: 2,
    fr: {
      date: "Octobre 2022",
      title: "Séminaire Arithmétique et Géométrie Algébrique",
      lieu: "Orsay, France",
    },
    en: {
      date: "October 2022",
      title: "Seminar Arithmétique et Géométrie Algébrique",
      lieu: "Orsay, France",
    },
  },
  {
    id: 17,
    marker: 3,
    fr: {
      date: "Juillet 2022",
      title: "AMS-SMF-EMS Joint International Meeting",
      lieu: "Grenoble, France",
    },
    en: {
      date: "July 2022",
      title: "AMS-SMF-EMS Joint International Meeting Special Sessions",
      lieu: "Grenoble, France",
    },
  },
  {
    id: 16,
    marker: 4,
    fr: {
      date: "Juin 2022",
      title: "Séminaire de Théorie des Nombres de l’ENS Lyon",
      lieu: "Lyon, France",
    },
    en: {
      date: "June 2022",
      title: "Seminar of Number Theory of the ENS Lyon",
      lieu: "Lyon, France",
    },
  },
  {
    id: 15,
    marker: 0,
    fr: {
      date: "Mai 2022",
      title: "French-Korean IRL in Mathematics",
      lieu: "Exposé en distanciel",
    },
    en: {
      date: "May 2022",
      title: "French-Korean IRL in Mathematics",
      lieu: "Online talk",
    },
  },
  {
    id: 14,
    marker: 5,
    fr: {
      date: "Mai 2022",
      title: "Paris-London Number Theory Seminar",
      lieu: "Londres, Royaume-Uni",
    },
    en: {
      date: "May 2022",
      title: "Paris-London Number Theory Seminar",
      lieu: "London, United Kingdom",
    },
  },
  {
    id: 13,
    marker: 2,
    fr: {
      date: "Mai 2022",
      title: "Séminaire du Laboratoire de Mathématiques de Versailles",
      lieu: "Versailles, France",
    },
    en: {
      date: "May 2022",
      title: "Seminar of the Laboratoire de Mathématiques de Versailles",
      lieu: "Versailles, France",
    },
  },
  {
    id: 12,
    marker: 6,
    fr: {
      date: "Mars 2022",
      title: "Séminaire Representation Theory and Automorphic Forms",
      lieu: "Vienne, Autriche",
    },
    en: {
      date: "March 2022",
      title: "Seminar Representation Theory and Automorphic Forms",
      lieu: "Vienna, Austria",
    },
  },
  {
    id: 11,
    marker: 7,
    fr: {
      date: "Octobre 2021",
      title: "Séminaire Groupes, Algèbre et Géométrie",
      lieu: "Poitiers, France",
    },
    en: {
      date: "October 2021",
      title: "Seminar Groupes, Algèbre et Géométrie",
      lieu: "Poitiers, France",
    },
  },
  {
    id: 10,
    marker: 8,
    fr: {
      date: "Avril 2021",
      title: "Séminaire de géométrie complexe",
      lieu: "Exposé en distanciel, Nancy, France",
    },
    en: {
      date: "April 2021",
      title: "Seminar Géométrie complexe",
      lieu: "Online talk, Nancy, France",
    },
  },
  {
    id: 9,
    marker: 9,
    fr: {
      date: "Janvier 2021",
      title: "Séminaire de théorie des groupes",
      lieu: "Exposé en distanciel, Amiens, France",
    },
    en: {
      date: "January 2021",
      title: "Seminar Théorie des groupes",
      lieu: "Online talk, Amiens, France",
    },
  },
  {
    id: 8,
    marker: 2,
    fr: {
      date: "November 2020",
      title: "Séminaire de Géométrie Arithmétique et Motivique",
      lieu: "Exposé en distanciel, Paris, France",
    },
    en: {
      date: "November 2020",
      title: "Seminar Géométrie Arithmétique et Motivique",
      lieu: "Online talk, Paris, France",
    },
  },
  {
    id: 7,
    marker: 5,
    fr: {
      date: "Décembre 2019",
      title: "London Number Theory Seminar",
      lieu: "Londres, Royaume-Uni",
    },
    en: {
      date: "December 2019",
      title: "London Number Theory Seminar",
      lieu: "London, United-Kingdom",
    },
  },
  {
    id: 6,
    marker: 7,
    fr: {
      date: "Février 2019",
      title: "Colloquium GDR TLAG",
      lieu: "Poitiers, France",
    },
    en: {
      date: "February  2019",
      title: "Colloquium GDR TLAG",
      lieu: "Poitiers, France",
    },
  },
  {
    id: 5,
    marker: 10,
    fr: {
      date: "Février 2019",
      title: "Séminaire University of East Anglia",
      lieu: "Norwich, Royaume-Uni",
    },
    en: {
      date: "February 2019",
      title: "Seminar of the University of East Anglia",
      lieu: "Norwich, United-Kingdom",
    },
  },
  {
    id: 4,
    marker: 6,
    fr: {
      date: "Décembre 2018",
      title: "Séminaire University of Vienna",
      lieu: "Vienne, Autriche",
    },
    en: {
      date: "December 2018",
      title: "Seminar of the University of Vienna",
      lieu: "Vienna, Austria",
    },
  },
  {
    id: 3,
    marker: 2,
    fr: {
      date: "Novembre 2018",
      title: "Séminaire du Laboratoire de Mathématiques de Versailles",
      lieu: "Versailles, France",
    },
    en: {
      date: "November 2018",
      title: "Seminar of the Laboratoire de Mathématiques de Versailles",
      lieu: "Versailles, France",
    },
  },
  {
    id: 2,
    marker: 2,
    fr: {
      date: "Février 2018",
      title: "Séminaire Groupes Réductifs et Formes Automorphes de l’IMJ-PRG",
      lieu: "Paris, France",
    },
    en: {
      date: "February 2018",
      title: "Seminar Groupes Réductifs et Formes Automorphes de l’IMJ-PRG",
      lieu: "Paris, France",
    },
  },
  {
    id: 1,
    marker: 2,
    fr: {
      date: "Novembre 2017",
      title: "Séminaire Groupes Réductifs et Formes Automorphes de l’IMJ-PRG",
      lieu: "Paris, France",
    },
    en: {
      date: "November 2017",
      title: "Seminar Groupes, Représentations et Géométrie",
      lieu: "Paris, France",
    },
  },
];
