import styled from "styled-components";

export const AProposContainer = styled.div`
  display: flex;
  /* align-items: center;
  justify-content: center; */
  flex-direction: column;

  padding-top: 100px;
  padding-bottom: 100px;
`;

export const AProposWrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  align-self: center;
`;

export const Row = styled.div`
  display: grid;
  grid-auto-columns: auto;
  /* align-items: center; */
  grid-template-areas: "col1 col2";

  @media screen and (max-width: 768px) {
    grid-template-areas: "col1" "col2";
  }
`;

export const Column1 = styled.div`
  /* margin-bottom: 15px;
  padding: 0 15px; */
  grid-area: col1;
  /* padding-left: 100px; */
  padding-top: 60px;
  z-index: 5;

  margin-left: 20px;
`;

export const Column2 = styled.div`
  /* margin-bottom: 15px;
  padding: 0 15px; */
  grid-area: col2;
  /* padding-left: 30px; */
  /* max-width: 500px; */
  /* transform: translateX(-150px); */
  margin-right: 20px;

  @media screen and (max-width: 768px) {
    transform: translateX(0px);
    margin-right: 0px;
  }
`;

export const PhotoWeb = styled.img`
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const PhotoMobile = styled.img`
  display: none;

  @media screen and (max-width: 768px) {
    display: flex;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
  }
`;

// export const AProposCard = styled.div`
//   background-color: #f9f9f9;
//   border-radius: 5px;
//   /* color: white; */
//   overflow: hidden;
//   box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
// `;

// export const CardTitle = styled.h3`
//   font-size: 40px;
//   text-transform: uppercase;
//   font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
//   font-weight: 700;
//   /* letter-spacing: 1px; */
// `;

// export const CardSubtitle = styled.h4`
//   font-size: 20px;
//   /* text-transform: uppercase; */
//   /* font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif; */
//   font-weight: 600;
//   /* letter-spacing: 1px; */
//   margin-bottom: 20px;
//   color: #0276e2;
// `;

export const Paragraphe = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 25px;

  color: #eaeaea;
  padding-left: 50px;
  margin-top: 30px;

  /* max-width: 700px; */

  margin-right: 150px;

  text-align: justify;

  @media screen and (max-width: 768px) {
    margin-right: 20px;
    padding-left: 0px;
  }
`;

export const SubjectTitle = styled.span`
  font-family: "Poppins";
  font-weight: bold;
  font-size: 30px;

  cursor: pointer;

  /* color: #2776ae; */
  color: ${({ isActive }) => (isActive ? "white" : "#2776ae")};
  text-shadow: 1px 0 0 white, 0 1px 0 white, -1px 0 0 white, 0 -1px 0 white;

  @supports ((text-stroke: 1px white) or (-webkit-text-stroke: 1px white)) {
    /* color: transparent; */
    color: ${({ isActive }) => (isActive ? "white" : "transparent")};

    -webkit-text-stroke: 1px white;
    text-stroke: 1px white;
    text-shadow: none;
  }
  transition: 0.2s all ease;

  /* &:hover {
    color: white;
    transition: 0.2s all ease;
  } */

  @media screen and (max-width: 768px) {
    font-size: 22px;
  }
`;

export const SubjectWrapper = styled.p`
  line-height: 35px;
`;
export const Column4 = styled.div`
  /* margin-bottom: 15px;
  padding: 0 15px; */
  grid-area: col2;
  /* padding-left: 30px; */
  max-width: 350px;
`;

export const Column3 = styled.div`
  /* margin-bottom: 15px;
  padding: 0 15px; */
  grid-area: col1;
`;

export const Description = styled.p`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 25px;

  color: #eaeaea;
`;

export const Row2 = styled.div`
  display: grid;
  grid-auto-columns: auto;
  /* align-items: center; */
  grid-template-areas: "col1 col2";

  @media screen and (max-width: 768px) {
    grid-template-areas: "col1" "col2";
  }
`;

export const AProposSubtitle = styled.h3`
  color: white;
  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 72px;

  @media screen and (max-width: 768px) {
    font-size: 25px;
    line-height: 50px;
  }
`;

export const AProposSubtitleWrapper = styled.div`
  align-self: center;
  padding-top: 50px;
  margin-bottom: 50px;
`;

export const Shape = styled.div`
  width: 230px;
  height: 230px;
  border: 2px solid #fff;
  border-radius: 126% 50% 80% 100% / 80% 50% 107% 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  &:hover {
    width: 600px;
    height: 200px;
    border-radius: 77% 87% 77% 90% / 54% 52% 114% 107%;
  }
`;

export const InnerShape = styled.div`
  width: 200px;
  height: 230px;
  border-radius: 50% 126% 100% 80% / 50% 80% 100% 107%;
  background-color: lightcoral;
  &:hover {
    width: 600px;
    height: 200px;
    border-radius: 77% 87% 77% 90% / 54% 52% 114% 107%;
  }
`;

export const FeaturesWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
