export const translations = {
  fr: {
    work: "Parcours professionnel",
    education: "Formation Universitaire",
    talks: "Exposés",
    internships: "Stages de Recherche",
    teaching: "Enseignements",
    cvbouton: "Version Pdf",
  },
  en: {
    work: "Work experience",
    education: "Education",
    talks: "Talks",
    internships: "Research Internships",
    teaching: "Teaching",
    cvbouton: "Pdf version",
  },
};

export const work = [
  {
    id: 2,
    fr: {
      date: "2022--",
      title: "Chargé de recherche CNRS",
      place: "Laboratoire de mathématiques de Versailles, UVSQ, Versailles",
      add1: "",
      add2: ""
    },
    en: {
      date: "2022--",
      title: "CNRS Research Fellow",
      place: "Laboratoire de mathématiques de Versailles, UVSQ, Versailles, France",
      add1: "",
      add2: ""
    }
  },
  {
    id: 1,
    fr: {
      date: "2019–2022",
      title: "Post-doctorat de Mathématiques",
      place: "University of Vienna, Vienne, Autriche",
      add1: "",
      add2: ""
    },
    en: {
      date: "2019–2022",
      title: "Post-Doc in Mathematics",
      place: "University of Vienna, Vienna, Austria",
      add1: "",
      add2: ""
    }
  },
]

export const education = [
  {
    id: 5,
    fr: {
      date: "2015-2019",
      title: "Doctorat de Mathématiques",
      place: "Université Pierre et Marie Curie, IMJ-PRG, Paris",
      add1: "Directeur : Jean-François Dat",
      add2: "Sujet : Sur les l-blocs des groupes p-adiques"
    },
    en: {
      date: "2015-2019",
      title: "PhD in Mathematics",
      place: "Université Pierre et Marie Curie, IMJ-PRG, Paris",
      add1: "Advisor : Jean-François Dat",
      add2: "Subject: On the l-locks of p-adic groups"
    }
  },
  {
    id: 4,
    fr: {
      date: "2014-2015",
      title: "Master (M2) de Mathématiques",
      place: "École Normale Supérieure de Lyon",
      add1: "",
      add2: "Sujet: Introduction à la théorie des fonctions zêta et L et à leurs applications."
    },
    en: {
      date: "2014-2015",
      title: "Master (M2) of Mathematics",
      place: "École Normale Supérieure de Lyon",
      add1: "",
      add2: "Subject: Introduction to the theory of L and zeta functions and their applications"
    }
  },
  {
    id: 3,
    fr: {
      date: "2013-2014",
      title: "Agrégation de Mathématiques",
      place: "École Normale Supérieure de Lyon, Lyon",
      add1: "Rang : 1er",
      add2: ""
    },
    en: {
      date: "2013-2014",
      title: "Agrégation of Mathematics",
      place: "École Normale Supérieure de Lyon, Lyon, France",
      add1: "Rank : 1st",
      add2: ""
    }
  },
  {
    id: 2,
    fr: {
      date: "2012-2013",
      title: "Master (M1) de Mathématiques, ERASMUS",
      place: "Imperial College London / École Normale Supérieure de Lyon",
      add1: "",
      add2: ""
    },
    en: {
      date: "2012-2013",
      title: "Master (M1) of Mathematics, ERASMUS",
      place: "Imperial College London / École Normale Supérieure de Lyon",
      add1: "",
      add2: ""
    }
  },
  {
    id: 1,
    fr: {
      date: "2011-2012",
      title: "Licence (L3) de Mathématiques",
      place: "École Normale Supérieure de Lyon",
      add1: "",
      add2: ""
    },
    en: {
      date: "2011-2012",
      title: "Bachelor of Mathematics",
      place: "École Normale Supérieure de Lyon, Lyon, France",
      add1: "",
      add2: ""
    }
  }
]

export const internship = [
  {
    id: 1,
    fr: {
      date: "2015",
      place: "Institut Mathématique de Jussieu",
      supervisor: "Jean François Dat",
      subject: "Représentations des groupes p-adiques et conjectures de Langlands"
    },
    en: {
      date: "2015",
      place: "Institut Mathématique de Jussieu",
      supervisor: "Jean François Dat",
      subject: "Representations of p-adic groups and Langlands conjectures"
    }
  },
  {
    id: 2,
    fr: {
      date: "2013",
      place: "Imperial College London",
      supervisor: "Kevin Buzzard",
      subject: "The Modularity Theorem"
    },
    en: {
      date: "2013",
      place: "Imperial College London",
      supervisor: "Kevin Buzzard",
      subject: "The Modularity Theorem"
    }
  },
  {
    id: 3,
    fr: {
      date: "2012",
      place: "École Polytechnique, Centre de Mathématiques Laurent Schwartz",
      supervisor: "Alain Plagne",
      subject: "Le problème de Waring"
    },
    en: {
      date: "2012",
      place: "École Polytechnique, Centre de Mathématiques Laurent Schwartz",
      supervisor: "Alain Plagne",
      subject: "Waring’s Problem"
    }
  }
]

export const teaching = [
  {
    id: 9,
    fr: {
      date: "2021-2022",
      title: "Linear Algebra",
      class: "L1, TD",
      place: "University of Vienna"
    },
    en: {
      date: "2021-2022",
      title: "Linear Algebra",
      class: "L1, TD",
      place: "University of Vienna"
    }
  },
  {
    id: 8,
    fr: {
      date: "2019-2021",
      title: "Algebraic Number Theory",
      class: "Master, TD",
      place: "University of Vienna"
    },
    en: {
      date: "2019-2021",
      title: "Algebraic Number Theory",
      class: "Master",
      place: "University of Vienna"
    }
  },
  {
    id: 7,
    fr: {
      date: "2020-2021",
      title: "Linear Algebra and Geometry",
      class: "L1, TD",
      place: "University of Vienna"
    },
    en: {
      date: "2020-2021",
      title: "Linear Algebra and Geometry",
      class: "L1",
      place: "University of Vienna"
    }
  },
  {
    id: 6,
    fr: {
      date: "2020-2021",
      title: "Théorie des nombres",
      class: "L1, TD",
      place: "University of Vienna"
    },
    en: {
      date: "2020-2021",
      title: "Number Theory",
      class: "L1",
      place: "University of Vienna"
    }
  },
  {
    id: 5,
    fr: {
      date: "2019–2020",
      title: "Modular Forms",
      class: "Master, TD",
      place: "University of Vienna"
    },
    en: {
      date: "2019–2020",
      title: "Modular Forms",
      class: "Master, TD",
      place: "University of Vienna"
    }
  },
  {
    id: 4,
    fr: {
      date: "2015-2019",
      title: "Mathématiques en formation continue",
      class: "L3, Cours+TD",
      place: "Polytech' Paris"
    },
    en: {
      date: "2015-2019",
      title: "Mathematics in continuing education",
      class: "L3, Autonomous",
      place: "Polytech' Paris"
    }
  },
  {
    id: 3,
    fr: {
      date: "2018-2019",
      title: "Théorie des Groupes",
      class: "L3, TD",
      place: "Sorbonne Université"
    },
    en: {
      date: "2018-2019",
      title: "Group theory",
      class: "L3",
      place: "Sorbonne Université"
    }
  },
  {
    id: 2,
    fr: {
      date: "2018-2019",
      title: "Arithmétique",
      class: "L2, TD",
      place: "Sorbonne Université"
    },
    en: {
      date: "2018-2019",
      title: "Arithmetic",
      class: "L2",
      place: "Sorbonne Université"
    }
  },
  {
    id: 1,
    fr: {
      date: "2015-2018",
      title: "Analyse de Fourier et Distributions",
      class: "L3, TD",
      place: "Polytech' Paris"
    },
    en: {
      date: "2015-2018",
      title: "Fourier analysis and Distributions",
      class: "L3",
      place: "Polytech' Paris"
    }
  }
]
