import styled from "styled-components";
import { Geography, Marker } from "react-simple-maps";

export const StyledGeography = styled(Geography)`
  fill: white;
  stroke: black;
  stroke-width: 0.4px;
  outline: none;
`;

export const InfoBulle = styled.text`
  display: none;
  fill: white;
  opacity: 0.9;
  font-size: 6px;
`;

export const StyledMarker = styled(Marker)`
  opacity: 0;
  color: black;
  &:hover {
    opacity: 1;
    color: red;
  }

  &:hover ${InfoBulle} {
    display: flex;
`;
