import React, { useState } from "react";

import { MainTitle, TitleWrapper } from "../TitleSection";
import { translations, tags, publiTypes, publications } from "./data";
import PublicationItem from "./PublicationItem";
import {
  PublicationsContainer,
  PublicationsWrapper,
  PubliSectionTitle,
  TagListWrapper,
} from "./PublicationsElements";
import TagList from "./TagList";

const Publications = ({ french }) => {
  const [activeTags, setActiveTags] = useState([]);
  const [activeTypes, setActiveTypes] = useState([]);
  const [activeSorts, setActiveSorts] = useState([0]);

  const sortTags = [
    { id: 0, fr: "Aucun", en: "None" },
    { id: 1, fr: "Date", en: "Date" },
    { id: 2, fr: "Type", en: "Type" },
  ];

  const onClickTag = (tag) => {
    const tagId = tag["id"];
    const index = activeTags.indexOf(tagId);
    if (index === -1) {
      setActiveTags([...activeTags, tagId]);
    } else {
      setActiveTags(
        activeTags.filter((i) => {
          return i !== tagId;
        })
      );
    }
  };

  const onClickTypes = (tag) => {
    const tagId = tag["id"];
    const index = activeTypes.indexOf(tagId);
    if (index === -1) {
      setActiveTypes([...activeTypes, tagId]);
    } else {
      setActiveTypes(
        activeTypes.filter((i) => {
          return i !== tagId;
        })
      );
    }
  };

  const onClickSort = (tag) => {
    const tagId = tag["id"];

    setActiveSorts([tagId]);
  };

  // const containsTag = (tagList) => {
  //   if (activeTags.length === 0) return true;
  //   let index = 0;
  //   while (index < activeTags.length) {
  //     if (tagList.indexOf(activeTags[index]) !== -1) return true;
  //     index++;
  //   }
  //   return false;
  // };

  const containsAllTag = (tagList) => {
    if (activeTags.length === 0) return true;
    for (const t of activeTags) if (tagList.indexOf(t) === -1) return false;
    return true;
  };

  const containsType = (type) => {
    if (activeTypes.length === 0) return true;
    if (activeTypes.indexOf(type) === -1) {
      return false;
    }
    return true;
  };

  const showPubli = (p) => {
    if (!containsAllTag(p["tags"])) {
      return false;
    }
    return containsType(p["publiType"]);
  };

  const listPubliType = (type) => {
    const list = [];
    let empty = true;
    for (const p of publications) {
      if (p["publiType"] === type) {
        list.push(
          <PublicationItem
            key={p["id"]}
            publi={p}
            tags={tags}
            french={french}
            visible={activeSorts[0] === 2 && showPubli(p)}
          />
        );
        empty = false;
      }
    }
    if (empty) return false;
    else return list;
  };

  const isPubliType = (type) => {
    for (const p of publications) {
      if (p["publiType"] === type && showPubli(p)) {
        return true;
      }
    }
    return false;
  };

  const getListDate = () => {
    let list = [];
    for (const p of publications) {
      if (list.indexOf(p.date) === -1) {
        list.push(p.date);
      }
    }
    list.sort(function (a, b) {
      return b - a;
    });
    return list;
  };

  const listPubliDate = (date) => {
    const list = [];
    let empty = true;
    for (const p of publications) {
      if (p["date"] === date) {
        list.push(
          <PublicationItem
            key={p["id"]}
            publi={p}
            tags={tags}
            french={french}
            visible={activeSorts[0] === 1 && showPubli(p)}
          />
        );
        empty = false;
      }
    }
    if (empty) return false;
    else return list;
  };

  const isPubliDate = (date) => {
    for (const p of publications) {
      if (p["date"] === date && showPubli(p)) {
        return true;
      }
    }
    return false;
  };

  return (
    <PublicationsContainer>
      <TitleWrapper>
        <MainTitle>{translations[french ? "fr" : "en"]["title"]}</MainTitle>
      </TitleWrapper>
      <TagListWrapper>
        <TagList
          title={translations[french ? "fr" : "en"]["types"]}
          tagList={publiTypes}
          activeTags={activeTypes}
          onClickTag={onClickTypes}
          hidden={false}
          french={french}
        />
        <TagList
          title={translations[french ? "fr" : "en"]["trier"]}
          tagList={sortTags}
          activeTags={activeSorts}
          onClickTag={onClickSort}
          hidden={false}
          french={french}
        />
        <TagList
          title={translations[french ? "fr" : "en"]["motcles"]}
          tagList={tags}
          activeTags={activeTags}
          onClickTag={onClickTag}
          hidden={true}
          french={french}
        />
      </TagListWrapper>

      <PublicationsWrapper>
        {publications.map((p) => (
          <PublicationItem
            key={p["id"]}
            publi={p}
            tags={tags}
            french={french}
            visible={activeSorts[0] === 0 && showPubli(p)}
          />
        ))}
        {publiTypes.map(
          (p) =>
            listPubliType(p["id"]) && (
              <div key={p["id"]}>
                <PubliSectionTitle
                  visible={activeSorts[0] === 2 && isPubliType(p["id"])}
                >
                  {p["fr"]}
                </PubliSectionTitle>
                {listPubliType(p["id"])}
              </div>
            )
        )}
        {getListDate().map(
          (d) =>
            listPubliDate(d) && (
              <div key={d}>
                <PubliSectionTitle
                  visible={activeSorts[0] === 1 && isPubliDate(d)}
                >
                  {d}
                </PubliSectionTitle>
                {listPubliDate(d)}
              </div>
            )
        )}
      </PublicationsWrapper>
    </PublicationsContainer>
  );
};

export default Publications;
