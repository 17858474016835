import styled from "styled-components";

export const PublicationNumber = styled.div`
  color: white;

  font-family: "Poppins";
  font-weight: bold;
  font-size: 96px;
  color: "#239B56";
  text-shadow: 1px 0 0 white, 0 1px 0 white, -1px 0 0 white, 0 -1px 0 white;
  margin-top: -25px;
  margin-right: 5px;

  @supports ((text-stroke: 1px white) or (-webkit-text-stroke: 1px white)) {
    color: transparent;

    -webkit-text-stroke: 1px white;
    text-stroke: 1px white;
    text-shadow: none;
  }

  @media screen and (max-width: 768px) {
    font-size: 50px;
    margin-top: -10px;
  }
`;

export const PublicationInfos = styled.div`
  display: flex;
  flex-direction: column;
`;

export const PublicationButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  @media screen and (max-width: 768px) {
    justify-content: left;
  }
`;

export const PublicationButtonPdf = styled.a`
  margin-left: 20px;
  font-size: 45px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: white;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: white;
    margin-left: 0px;
  }

  &:hover {
    transform: scale(1.2);
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
    display: flex;
    flex-direction: row;
  }
`;
export const PublicationButtonResume = styled.div`
  margin-left: 20px;
  font-size: 35px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    transform: scale(1.2);
  }

  @media screen and (max-width: 768px) {
    font-size: 17px;
    display: flex;
    flex-direction: row;
    margin-left: 10px;
  }
`;

export const PublicationTitle = styled.div`
  font-family: "Poppins";
  font-weight: bold;
  font-size: 24px;

  color: #ffffff;
  @media screen and (max-width: 768px) {
    font-size: 21px;
  }
`;

export const PublicationRevue = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #ffffff;

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`;

export const PublicationCoAuthor = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;

  color: #ffffff;

  @media screen and (max-width: 768px) {
    font-size: 15px;
  }
`;

export const PublicationTags = styled.div`
  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  margin-top: 10px;

  color: #ffffff;
`;

export const PublicationWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 768px) {
    flex-direction: column;
  }
`;

export const PublicationClickArea = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  cursor: pointer;
  &:hover {
    ${PublicationNumber} {
      color: white;
    }
  }
`;

export const Abstract = styled.div`
  color: white;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  margin-top: 10px;
  text-align: justify;

  @media screen and (max-width: 768px) {
    margin-right: 20px;
    margin-left: 20px;
  }
`;

export const TextMobile = styled.div`
  display: none;
  @media screen and (max-width: 768px) {
    display: flex;
    font-size: 14px;
    margin-right: 3px;
  }
`;

export const VisibleDiv = styled.div`
  display: ${({ visible }) => (visible ? "" : "none")};
`;
export const VisibleSpan = styled.span`
  display: ${({ visible }) => (visible ? "" : "none")};
`;

export const PublicationNumberTransparent = styled.div`
  display: none;
  color: transparent;

  font-family: "Poppins";
  font-weight: bold;
  font-size: 96px;
  margin-right: 5px;

  @media screen and (max-width: 768px) {
    display: flex;
    font-size: 50px;
    margin-top: -10px;
  }
`;
