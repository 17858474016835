import styled from "styled-components";

export const MainTitle = styled.h2`
  color: white;

  font-family: "Poppins";
  font-style: normal;
  font-weight: bold;
  font-size: 48px;
  line-height: 72px;

  @media screen and (max-width: 768px) {
    font-size: 36px;
    line-height: 50px;
  }
`;

export const TitleWrapper = styled.div`
  align-self: center;
  padding-top: 100px;
  padding-bottom: 50px;
`;
