import styled from "styled-components";

export const CVContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
`;

export const CVWrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  align-self: center;
`;

export const CVTitleSection = styled.h2`
  text-align: left;
  color: white;
  font-size: 25px;
  margin: 0 0 30px;
  text-transform: uppercase;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;

  &:before {
    content: "";
    display: inline-block;
    margin-right: 1%;
    width: 15%;
    height: 10px;
    background-color: white;
  }
`;

export const CVItemsWrapper = styled.dl``;

export const CVDate = styled.dt`
  font-weight: bold;

  @media screen and (min-width: 768px) {
    float: left;
    clear: left;
    width: 15%;
    text-align: right;
    padding-right: 10px;
  }
`;

export const CVContent = styled.dd`
  margin-bottom: 15px;
  @media screen and (min-width: 768px) {
    margin-left: 16%;
  }
`;

export const CVPdfWrapper = styled.div`
  margin-bottom: 20px;
`;

export const CVButtonPdf = styled.a`
  font-size: 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  color: white;
  background-color: transparent;
  border: 1px solid white;
  border-radius: 10px;
  padding: 5px;

  &:focus,
  &:hover,
  &:visited,
  &:link,
  &:active {
    text-decoration: none;
    color: white;
    margin-left: 0px;
  }

  &:hover {
    background-color: white;
    color: black;
  }

  @media screen and (max-width: 768px) {
    font-size: 20px;
  }
`;
