import styled from "styled-components";
import img1 from "../../images/maths1.png";
import img2 from "../../images/maths2.png";
import { Link } from "react-scroll";

export const HeroContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  height: 90vh;
`;

export const HeroTitle = styled.div`
  margin-top: 45vh;
  text-align: center;
  font-size: 70px;
  text-transform: uppercase;
  font-family: "Montserrat", "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-weight: 700;
  letter-spacing: 1px;
`;

export const HeroBackgroundImage1 = styled.div`
  @media screen and (min-width: 768px) {
    width: 1024px;
    height: 450px;
    margin-left: -512px;
    margin-top: -187px;
    background-image: url(${img1});
    position: absolute;
    top: 45%;
    left: 50%;
    opacity: 0.8;
    z-index: -1;
  }
`;
export const HeroBackgroundImage2 = styled.div`
  @media screen and (min-width: 768px) {
    width: 1024px;
    height: 450px;
    margin-left: -512px;
    margin-top: -187px;
    background-image: url(${img2});
    position: absolute;
    top: 45%;
    left: 50%;
    opacity: 0.8;
    z-index: -1;
  }
`;

export const HeroImagesWrapper = styled.div`
  flex: 1;
`;

export const BtnScrollWrapper = styled.div`
  display: flex;
  opacity: ${({ scrollLottie }) => (scrollLottie ? "0" : "1")};
  transition: all 1s ease-in-out;
`;

export const BtnScrollLink = styled(Link)`
  height: 60px;
`;

export const TitleLink = styled(Link)`
  cursor: pointer;
`;
