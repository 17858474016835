import React, { useState } from "react";
import {
  TagTitle,
  TagWrapper,
  TagItem,
  BoutonPlus,
  VisibleSpan,
} from "./TagListElements";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import { MathJax } from "better-react-mathjax";

const TagList = ({
  title,
  tagList,
  activeTags,
  onClickTag,
  hidden,
  french,
}) => {
  const [showList, setShowList] = useState(false);

  const displayTag = (tagId) => {
    if (activeTags.indexOf(tagId) === -1) {
      return false;
    }

    return true;
  };

  const changeList = () => {
    setShowList(!showList);
  };

  return (
    <TagWrapper>
      <TagTitle
        effect={hidden}
        onClick={changeList}
        className={`${hidden ? "link" : ""}`}
      >
        {title}
        <BoutonPlus>
          {hidden &&
            (showList ? <AiOutlineMinusCircle /> : <AiOutlinePlusCircle />)}
        </BoutonPlus>
      </TagTitle>
      {tagList.map(
        (t) =>
          !hidden && (
            <TagItem
              key={t["id"]}
              active={displayTag(t["id"])}
              visible={true}
              onClick={() => {
                onClickTag(t);
              }}
            >
              <VisibleSpan visible={french}>
                <MathJax>{t["fr"]}</MathJax>
              </VisibleSpan>
              <VisibleSpan visible={!french}>
                <MathJax>{t["en"]}</MathJax>
              </VisibleSpan>
            </TagItem>
          )
      )}
      {tagList.map(
        (t) =>
          hidden && (
            <TagItem
              key={t["id"]}
              active={displayTag(t["id"])}
              visible={displayTag(t["id"])}
              onClick={() => {
                onClickTag(t);
              }}
            >
              <VisibleSpan visible={french}>
                <MathJax>{t["fr"]}</MathJax>
              </VisibleSpan>
              <VisibleSpan visible={!french}>
                <MathJax>{t["en"]}</MathJax>
              </VisibleSpan>
            </TagItem>
          )
      )}
      <br />
      {tagList.map(
        (t) =>
          hidden && (
            <TagItem
              key={t["id"]}
              active={displayTag(t["id"])}
              visible={showList && !displayTag(t["id"])}
              onClick={() => {
                onClickTag(t);
              }}
            >
              <VisibleSpan visible={french}>
                <MathJax>{t["fr"]}</MathJax>
              </VisibleSpan>
              <VisibleSpan visible={!french}>
                <MathJax>{t["en"]}</MathJax>
              </VisibleSpan>
            </TagItem>
          )
      )}
    </TagWrapper>
  );
};

export default TagList;
