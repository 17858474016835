import React from "react";
import { MainTitle, TitleWrapper } from "../TitleSection";
import {
  CVContainer,
  CVTitleSection,
  CVDate,
  CVContent,
  CVWrapper,
  CVItemsWrapper,
  CVButtonPdf,
  CVPdfWrapper,
} from "./CVElements";
import { translations, work, education, internship, teaching } from "./data";
import { AiFillFilePdf } from "react-icons/ai";

const CV = ({ french }) => {
  return (
    <CVContainer>
      <TitleWrapper>
        <MainTitle>CV</MainTitle>
      </TitleWrapper>

      <CVWrapper>
        <CVPdfWrapper>
          <CVButtonPdf href={french ? "./cv/cvfr.pdf" : "./cv/cven.pdf"}>
            {translations[french ? "fr" : "en"]["cvbouton"]}&ensp;
            <AiFillFilePdf />
          </CVButtonPdf>
        </CVPdfWrapper>

        <CVTitleSection>
          {translations[french ? "fr" : "en"]["work"]}
        </CVTitleSection>

        <CVItemsWrapper>
          {work.map((c) => (
            <div key={c["id"]}>
              <CVDate>{c[french ? "fr" : "en"]["date"]}</CVDate>
              <CVContent>
                <strong>{c[french ? "fr" : "en"]["title"]}</strong>
                <br />
                <em>{c[french ? "fr" : "en"]["place"]}</em>
                {c[french ? "fr" : "en"]["add1"] !== "" && (
                  <>
                    <br />
                    {c[french ? "fr" : "en"]["add1"]}
                  </>
                )}
                {c[french ? "fr" : "en"]["add2"] !== "" && (
                  <>
                    <br />
                    <br />
                    {c[french ? "fr" : "en"]["add2"]}
                  </>
                )}
              </CVContent>
            </div>
          ))}
        </CVItemsWrapper>

        <CVTitleSection>
          {translations[french ? "fr" : "en"]["education"]}
        </CVTitleSection>

        <CVItemsWrapper>
          {education.map((c) => (
            <div key={c["id"]}>
              <CVDate>{c[french ? "fr" : "en"]["date"]}</CVDate>
              <CVContent>
                <strong>{c[french ? "fr" : "en"]["title"]}</strong>
                <br />
                <em>{c[french ? "fr" : "en"]["place"]}</em>
                {c[french ? "fr" : "en"]["add1"] !== "" && (
                  <>
                    <br />
                    {c[french ? "fr" : "en"]["add1"]}
                  </>
                )}
                {c[french ? "fr" : "en"]["add2"] !== "" && (
                  <>
                    <br />
                    <br />
                    {c[french ? "fr" : "en"]["add2"]}
                  </>
                )}
              </CVContent>
            </div>
          ))}
        </CVItemsWrapper>

        <CVTitleSection>
          {translations[french ? "fr" : "en"]["internships"]}
        </CVTitleSection>

        <CVItemsWrapper>
          {internship.map((c) => (
            <div key={c["id"]}>
              <CVDate>{c[french ? "fr" : "en"]["date"]}</CVDate>
              <CVContent>
                {c[french ? "fr" : "en"]["place"]}
                <br />
                <em>{c[french ? "fr" : "en"]["supervisor"]}</em>
                <br />
                {c[french ? "fr" : "en"]["subject"]}
              </CVContent>
            </div>
          ))}
        </CVItemsWrapper>

        <CVTitleSection>
          {translations[french ? "fr" : "en"]["teaching"]}
        </CVTitleSection>

        <CVItemsWrapper>
          {teaching.map((c) => (
            <div key={c["id"]}>
              <CVDate>{c[french ? "fr" : "en"]["date"]}</CVDate>
              <CVContent>
                <strong>{c[french ? "fr" : "en"]["title"]}</strong>
                <br />
                {c[french ? "fr" : "en"]["class"]}
                <br />
                <em>{c[french ? "fr" : "en"]["place"]}</em>
              </CVContent>
            </div>
          ))}
        </CVItemsWrapper>
      </CVWrapper>
    </CVContainer>
  );
};

export default CV;
