import React, { useState } from "react";
import { MainTitle, TitleWrapper } from "../TitleSection";
import {
  ExposesContainer,
  ExposesDate,
  ExposesContent,
  ExposesWrapper,
  ExposesItemsWrapper,
  IntroWrapper,
  BoutonPlus,
} from "./ExposesElements";
import { translations, talks } from "./data";
import { AiOutlinePlusCircle, AiOutlineMinusCircle } from "react-icons/ai";
import Map from "./Map";

const Exposes = ({ french }) => {
  const [nbExposes, setnbExposes] = useState(10);

  const clickPlus = () => {
    if (nbExposes === 10) setnbExposes(talks.length);
    else setnbExposes(10);
  };

  return (
    <ExposesContainer>
      <TitleWrapper>
        <MainTitle>{translations[french ? "fr" : "en"]["title"]}</MainTitle>
      </TitleWrapper>

      <Map french={french} />

      <ExposesWrapper>
        <IntroWrapper>
          {nbExposes === 10
            ? translations[french ? "fr" : "en"]["intromoins"]
            : translations[french ? "fr" : "en"]["introplus"]}
          &nbsp;
          <BoutonPlus onClick={clickPlus} className={"link"}>
            (
            {nbExposes === 10
              ? translations[french ? "fr" : "en"]["voirplus"]
              : translations[french ? "fr" : "en"]["voirmoins"]}
            &nbsp;
            {nbExposes === 10 ? (
              <AiOutlinePlusCircle />
            ) : (
              <AiOutlineMinusCircle />
            )}
            )
          </BoutonPlus>
          &nbsp;:
        </IntroWrapper>
        <ExposesItemsWrapper>
          {talks.slice(0, nbExposes).map((c) => (
            <div key={c["id"]}>
              <ExposesDate>{c[french ? "fr" : "en"]["date"]}</ExposesDate>
              <ExposesContent>
                {c[french ? "fr" : "en"]["title"]}
                {", "}
                {c[french ? "fr" : "en"]["lieu"]}
              </ExposesContent>
            </div>
          ))}
        </ExposesItemsWrapper>
      </ExposesWrapper>
    </ExposesContainer>
  );
};

export default Exposes;
