import React from "react";
import { translations } from "../data";
import fr from "../../../images/fr.svg";
import en from "../../../images/en.svg";

import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SideLanguageWrap,
  SidebarLink,
  SidebarMenu,
  SideFlag,
} from "./SidebarElements";

const Sidebar = ({ isOpen, toggle, french, changeFr }) => {
  const setFr = () => {
    if (!french) {
      changeFr();
    }
  };
  const setEn = () => {
    if (french) {
      changeFr();
    }
  };

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarLink to="apropos" onClick={toggle}>
            {translations[french ? "fr" : "en"]["apropos"]}
          </SidebarLink>
          <SidebarLink to="publications" onClick={toggle}>
            {translations[french ? "fr" : "en"]["publications"]}
          </SidebarLink>
          <SidebarLink to="exposes" onClick={toggle}>
            {translations[french ? "fr" : "en"]["exposes"]}
          </SidebarLink>
          <SidebarLink to="cv" onClick={toggle}>
            {translations[french ? "fr" : "en"]["cv"]}
          </SidebarLink>
          <SidebarLink to="contact" onClick={toggle}>
            {translations[french ? "fr" : "en"]["contact"]}
          </SidebarLink>
        </SidebarMenu>
        <SideLanguageWrap>
          <SideFlag
            src={fr}
            alt="fr"
            width="30"
            height="30"
            french={french}
            onClick={setFr}
          />
          <SideFlag
            src={en}
            alt="en"
            width="30"
            height="30"
            french={!french}
            onClick={setEn}
          />
        </SideLanguageWrap>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
