import React from "react";
import { MainTitle, TitleWrapper } from "../TitleSection";
import { MdPlace } from "react-icons/md";
import { HiMail } from "react-icons/hi";
import Obfuscate from "react-obfuscate";
import {
  Column1,
  Column2,
  ContactContainer,
  ListIcon,
  Row,
  Icon,
  Mail,
  SousTitre,
} from "./Contactelements";
import { translations } from "./data";
import { adresseEmail } from "../Email";

const Contact = ({ french }) => {
  return (
    <ContactContainer>
      <TitleWrapper>
        <MainTitle>{translations[french ? "fr" : "en"]["titre"]}</MainTitle>
      </TitleWrapper>
      <Row>
        <Column1>
          <ListIcon>
            <Icon>
              <HiMail />
            </Icon>
            <div>
              <SousTitre>
                {translations[french ? "fr" : "en"]["mail"]}
              </SousTitre>
              <Mail>
                <Obfuscate email={adresseEmail()} />
              </Mail>
            </div>
          </ListIcon>
          <ListIcon>
            <Icon>
              <MdPlace />
            </Icon>
            <div>
              <SousTitre>
                {translations[french ? "fr" : "en"]["adresse"]}
              </SousTitre>
              {translations[french ? "fr" : "en"]["bureau"]} 3307
              <br />
              Laboratoire de Mathématiques de Versailles
              <br />
              Université de Versailles Saint-Quentin en Yvelines
              <br />
              45 avenue des États-Unis
              <br />
              78035 Versailles cedex
              <br />
              France
            </div>
          </ListIcon>
        </Column1>
        <Column2>
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2627.4907831570335!2d2.1463626508384404!3d48.81069661191676!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47e67c501af496e7%3A0x2701f97ca60d6da6!2sUVSQ%20-%20UFR%20des%20Sciences%20-%20Universit%C3%A9%20Paris-Saclay!5e0!3m2!1sfr!2sfr!4v1669910513726!5m2!1sfr!2sfr"
            width="400"
            height="400"
            style={{ border: 0 }}
            allowFullScreen
            loading="lazy"
            title="GoogleMaps"
          ></iframe>
        </Column2>
      </Row>
    </ContactContainer>
  );
};

export default Contact;
