export const translations = {
  fr: {
    apropos: "À Propos",
    publications: "Publications",
    exposes: "Exposés",
    cv: "CV",
    contact: "Contact",
  },
  en: {
    apropos: "About Me",
    publications: "Papers",
    exposes: "Talks",
    cv: "CV",
    contact: "Contact",
  },
};
