export const translations = {
  fr: {
    avec: "Avec : ",
    motcles: "Mots-clés : ",
    resume: "Résumé",
    resume2: "Résumé :",
  },
  en: {
    avec: "With : ",
    motcles: "Keywords: ",
    resume: "Abstract",
    resume2: "Abstract:",
  },
};
