import React, { useState, useEffect, useCallback, useRef } from "react";
import { useEventListener } from "./hooks/useEventListener";
import IsDevice from "./helpers/IsDevice";

/**
 * Cursor Core
 * Replaces the native cursor with a custom animated cursor, consisting
 * of an inner and outer dot that scale inversely based on hover or click.
 *
 * @author Stephen Scaff (github.com/stephenscaff)
 *
 * @param {number} size - outer cursor size in px
 * @param {number} scale - outer cursor scale amount
 *
 * Modified Version Here
 */
function CursorCore({ size = 18, scale = 5 }) {
  const cursorRef = useRef();
  const filter1 = useRef();
  const filter2 = useRef();
  const [isVisible, setIsVisible] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [isActiveClickable, setIsActiveClickable] = useState(false);
  let endX = useRef(0);
  let endY = useRef(0);

  // Primary Mouse Move event
  const onMouseMove = useCallback(({ clientX, clientY }) => {
    cursorRef.current.style.top = `${clientY}px`;
    cursorRef.current.style.left = `${clientX}px`;
    filter1.current.style.top = `${clientY}px`;
    filter1.current.style.left = `${clientX}px`;
    filter2.current.style.top = `${clientY}px`;
    filter2.current.style.left = `${clientX}px`;
    endX.current = clientX;
    endY.current = clientY;
  }, []);

  // Mouse Events State updates
  const onMouseDown = useCallback(() => {
    setIsActive(true);
  }, []);

  const onMouseUp = useCallback(() => {
    setIsActive(false);
  }, []);

  const onMouseEnterViewport = useCallback(() => {
    setIsVisible(true);
  }, []);

  const onMouseLeaveViewport = useCallback(() => {
    setIsVisible(false);
  }, []);

  useEventListener("mousemove", onMouseMove);
  useEventListener("mousedown", onMouseDown);
  useEventListener("mouseup", onMouseUp);
  useEventListener("mouseover", onMouseEnterViewport);
  useEventListener("mouseout", onMouseLeaveViewport);

  // Cursors Hover/Active State
  useEffect(() => {
    if (isActive) {
      cursorRef.current.style.transform = `translate(-50%, -50%) scale(${scale})`;
      filter1.current.style.transform = `translate(-50%, -50%) scale(${scale})`;
      filter2.current.style.transform = `translate(-50%, -50%) scale(${scale})`;
    } else {
      cursorRef.current.style.transform = "translate(-50%, -50%) scale(1)";
      filter1.current.style.transform = "translate(-50%, -50%) scale(1)";
      filter2.current.style.transform = "translate(-50%, -50%) scale(1)";
    }
  }, [scale, isActive]);

  // Cursors Click States
  useEffect(() => {
    if (isActiveClickable) {
      cursorRef.current.style.transform = `translate(-50%, -50%) scale(${
        scale * 1.4
      })`;
      filter1.current.style.transform = `translate(-50%, -50%) scale(${
        scale * 1.4
      })`;
      filter2.current.style.transform = `translate(-50%, -50%) scale(${
        scale * 1.4
      })`;
    }
  }, [scale, isActiveClickable]);

  // Cursor Visibility State
  useEffect(() => {
    if (isVisible) {
      cursorRef.current.style.opacity = 1;
      filter1.current.style.opacity = 1;
    } else {
      cursorRef.current.style.opacity = 0;
      filter1.current.style.opacity = 0;
    }
  }, [isVisible]);

  // Target all possible clickables
  useEffect(() => {
    const clickables = document.querySelectorAll(
      'a, input[type="submit"], input[type="image"], label[for], select, button, .link'
    );
    clickables.forEach((el) => {
      el.style.cursor = "none";

      el.addEventListener("mouseover", () => {
        setIsActive(true);
      });
      el.addEventListener("click", () => {
        setIsActive(true);
        setIsActiveClickable(false);
      });
      el.addEventListener("mousedown", () => {
        setIsActiveClickable(true);
      });
      el.addEventListener("mouseup", () => {
        setIsActive(true);
      });
      el.addEventListener("mouseout", () => {
        setIsActive(false);
        setIsActiveClickable(false);
      });
    });

    return () => {
      clickables.forEach((el) => {
        el.removeEventListener("mouseover", () => {
          setIsActive(true);
        });
        el.removeEventListener("click", () => {
          setIsActive(true);
          setIsActiveClickable(false);
        });
        el.removeEventListener("mousedown", () => {
          setIsActiveClickable(true);
        });
        el.removeEventListener("mouseup", () => {
          setIsActive(true);
        });
        el.removeEventListener("mouseout", () => {
          setIsActive(false);
          setIsActiveClickable(false);
        });
      });
    };
  }, [isActive]);

  // Cursor Styles
  const styles = {
    backgroundClip: "text",
    cursor: {
      zIndex: 999,
      display: "block",
      position: "fixed",
      borderRadius: "50%",
      pointerEvents: "none",
      width: size,
      height: size,
      mixBlendMode: "difference",
      backgroundColor: "white",
      transition: "opacity 0.15s ease-in-out, transform 0.15s ease-in-out",
      willChange: "transform",
    },
    filter: {
      zIndex: 999,
      display: "block",
      position: "fixed",
      borderRadius: "50%",
      pointerEvents: "none",
      width: size,
      height: size,
      mixBlendMode: "overlay",
      backgroundColor: "white",
      transition: "opacity 0.15s ease-in-out, transform 0.15s ease-in-out",
      willChange: "transform",
    },
  };

  // Hide / Show global cursor
  document.body.style.cursor = "none";

  return (
    <React.Fragment>
      <div ref={cursorRef} style={styles.cursor} />
      <div ref={filter1} style={styles.filter} />
      <div ref={filter2} style={styles.filter} />
    </React.Fragment>
  );
}

/**
 * AnimatedCursor
 * Calls and passes props to CursorCore if not a touch/mobile device.
 */
function AnimatedCursor({ size = 8, scale = 6 }) {
  if (typeof navigator !== "undefined" && IsDevice.any()) {
    return <React.Fragment></React.Fragment>;
  }
  return <CursorCore size={size} scale={scale} />;
}

export default AnimatedCursor;
