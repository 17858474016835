import React, { useState } from "react";
import { MathJax } from "better-react-mathjax";
import { translations } from "./data";
import {
  PublicationButtonPdf,
  PublicationButtonResume,
  PublicationInfos,
  PublicationNumber,
  PublicationRevue,
  PublicationTags,
  PublicationTitle,
  PublicationWrapper,
  PublicationButtonWrapper,
  PublicationClickArea,
  Abstract,
  PublicationCoAuthor,
  TextMobile,
  PublicationNumberTransparent,
  VisibleDiv,
  VisibleSpan,
} from "./PublicationItemElements";
import {
  AiFillFilePdf,
  AiOutlinePlusCircle,
  AiOutlineMinusCircle,
} from "react-icons/ai";

const PublicationItem = ({ publi, tags, french, visible }) => {
  const [showResume, setShowResume] = useState(false);

  const tagName = (tagId, lang) => {
    return tags.find((x) => x["id"] === tagId)[lang];
  };

  const changeResume = () => {
    setShowResume(!showResume);
  };
  return (
    <>
      <VisibleDiv visible={visible}>
        <PublicationWrapper>
          <PublicationClickArea onClick={changeResume} className={"link"}>
            <PublicationNumber>{publi["id"]}</PublicationNumber>
            <PublicationInfos>
              <PublicationTitle>
                <MathJax>{publi[french ? "fr" : "en"]["title"]}</MathJax>
              </PublicationTitle>
              <PublicationRevue>
                {publi[french ? "fr" : "en"]["journal"]}
              </PublicationRevue>
              <PublicationCoAuthor>
                {publi["coAuthors"].length > 0
                  ? [
                      translations[french ? "fr" : "en"]["avec"],
                      publi["coAuthors"].map((t, i) => [i > 0 && ", ", t]),
                    ]
                  : ""}
              </PublicationCoAuthor>
              <PublicationTags>
                {translations[french ? "fr" : "en"]["motcles"]}
                <VisibleSpan visible={french}>
                  {publi["tags"].map((t, i) => [
                    i > 0 && ", ",
                    tagName(t, "fr"),
                  ])}
                </VisibleSpan>
                <VisibleSpan visible={!french}>
                  {publi["tags"].map((t, i) => [
                    i > 0 && ", ",
                    tagName(t, "en"),
                  ])}
                </VisibleSpan>
              </PublicationTags>
            </PublicationInfos>
          </PublicationClickArea>

          <PublicationButtonWrapper>
            <PublicationNumberTransparent>
              {publi["id"]}
            </PublicationNumberTransparent>
            <PublicationButtonPdf href={"./publications/" + publi["file"]}>
              <TextMobile className={"link"}>Pdf</TextMobile>
              <AiFillFilePdf />
            </PublicationButtonPdf>
            <PublicationButtonResume onClick={changeResume} className={"link"}>
              <TextMobile>
                {translations[french ? "fr" : "en"]["resume"]}
              </TextMobile>
              {showResume ? <AiOutlineMinusCircle /> : <AiOutlinePlusCircle />}
            </PublicationButtonResume>
          </PublicationButtonWrapper>
        </PublicationWrapper>

        {showResume && (
          <Abstract>
            <VisibleDiv visible={french}>
              <MathJax>
                {" "}
                {translations["fr"]["resume2"]} {publi["fr"]["abstract"]}
              </MathJax>
            </VisibleDiv>
            <VisibleDiv visible={!french}>
              <MathJax>
                {" "}
                {translations["en"]["resume2"]} {publi["en"]["abstract"]}
              </MathJax>
            </VisibleDiv>
          </Abstract>
        )}
        <br />
      </VisibleDiv>
    </>
  );
};

export default PublicationItem;
