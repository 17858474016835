import styled from "styled-components";
import { Link } from "react-scroll";

export const NavLogo = styled.div`
  justify-self: flex-start;
  font-size: 25px;
  display: flex;
  align-items: center;
  margin-left: 20px;
  margin-top: 5px;
  text-decoration: none;

  transition: all 1s ease-in-out;
  a:focus,
  a:hover,
  a:visited,
  a:link,
  a:active {
    text-decoration: none;
    color: white;
  }

  a:hover {
    transition: all 0.1s ease-in-out;
    transform: scale(1.3);
  }

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Nav = styled.nav`
  background-color: ${({ scrollNav }) => (scrollNav ? "#000" : "transparent")};
  height: ${({ scrollNav }) => (scrollNav ? "60px" : "100px")};
  margin-top: ${({ scrollNav }) => (scrollNav ? "-60px" : "-100px")};
  display: flex;
  justify-content: center;
  align-items: center;
  position: sticky;
  top: 0;
  z-index: 10;
  transition: 1s all ease-in-out;
  margin-bottom: 0;
  text-transform: uppercase;
  font-size: ${({ scrollNav }) => (scrollNav ? "14px" : "16px")};

  /* font-size: 14px; */
  font-family: "Montserrat";

  /* ${NavLogo} {
    color: ${({ scrollNav }) => (scrollNav ? "#fff" : "transparent")};
    cursor: ${({ scrollNav }) => (scrollNav ? "pointer" : "default")};
  } */
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 50%);
    font-size: 1.8rem;
    /* cursor: pointer; */
    color: white;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  /* margin-left: 0px; */
  letter-spacing: 1px;

  /* @media screen and (max-width: 950px) {
    margin-left: 0;
  } */
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 60px;
`;

export const NavLinks = styled(Link)`
  color: #fff;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  /* cursor: pointer; */
  margin-left: 5px;
  margin-right: 5px;

  &.active {
    border-bottom: 3px solid white;
  }

  &:hover {
    border-bottom: 3px solid white;
  }
`;

export const LanguageChoice = styled.img`
  /* cursor: pointer; */
  margin-top: 10px;

  display: ${({ showMenu }) => (showMenu ? "block" : "none")};
  &:hover {
    transition: all 0.1s ease-in-out;
    transform: scale(1.3);
  }
`;

export const LanguageSwitcher = styled.div`
  justify-self: flex-start;

  display: flex;
  margin-right: 20px;
  margin-top: 25px;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const LanguageWrapper = styled.div`
  flex-direction: column;
  align-items: center;
`;
