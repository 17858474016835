export const translations = {
    fr: {
      titre: "Contact",
      adresse: "Adresse",
      mail: "Email",
      bureau: "Bureau,"
    },
    en: {
      titre: "Contact",
      adresse: "Address",
      mail: "Email",
      bureau: "Office",
    },
  };
  