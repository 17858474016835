import React, { useState, useEffect } from "react";
import { FaBars } from "react-icons/fa";
import { HiMail } from "react-icons/hi";
import { animateScroll as scroll } from "react-scroll";
import fr from "../../../images/fr.svg";
import en from "../../../images/en.svg";
import { translations } from "../data";
import Obfuscate from "react-obfuscate";
import { adresseEmail } from "../../Email";

import {
  Nav,
  NavbarContainer,
  NavLogo,
  MobileIcon,
  NavMenu,
  NavItem,
  NavLinks,
  LanguageSwitcher,
  LanguageChoice,
  LanguageWrapper,
} from "./NavbarElements";

const NavbarWeb = ({ toggle, french, changeFr }) => {
  const [scrollNav, setScrollNav] = useState(false);
  const [showMenu, setShowMenu] = useState(false);

  const duration = 700;

  const changeNav = () => {
    if (window.scrollY > 200) {
      setScrollNav(true);
    } else {
      setScrollNav(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", changeNav);
  }, []);

  const toggleLanguage = () => {
    changeFr();
    setShowMenu(false);
  };
  const openSelector = () => {
    setShowMenu(true);
  };
  const closeSelector = () => {
    setShowMenu(false);
  };

  return (
    <>
      <Nav scrollNav={scrollNav}>
        <NavbarContainer>
          <NavLogo>
            {/* <a href="mailto:san@antonio.net">
              <HiMail />
            </a> */}
            <Obfuscate email={adresseEmail()}>
              <HiMail />
            </Obfuscate>
          </NavLogo>

          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks
                to="apropos"
                smooth={true}
                duration={duration}
                spy={true}
                exact="true"
                offset={-60}
              >
                {translations[french ? "fr" : "en"]["apropos"]}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="publications"
                smooth={true}
                duration={duration}
                spy={true}
                exact="true"
                offset={-60}
              >
                {translations[french ? "fr" : "en"]["publications"]}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="exposes"
                smooth={true}
                duration={duration}
                spy={true}
                exact="true"
                offset={-60}
              >
                {translations[french ? "fr" : "en"]["exposes"]}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="cv"
                smooth={true}
                duration={duration}
                spy={true}
                exact="true"
                offset={-60}
              >
                {translations[french ? "fr" : "en"]["cv"]}
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks
                to="contact"
                smooth={true}
                duration={duration}
                spy={true}
                exact="true"
                offset={-60}
              >
                {translations[french ? "fr" : "en"]["contact"]}
              </NavLinks>
            </NavItem>
          </NavMenu>
          {/* <NavBtn>
            <NavBtnLink
              to="signin"
              smooth={true}
              duration={500}
              spy={true}
              exact="true"
              offset={-80}
            >
              {/* <IoMailOutline /> */}
          {/* Mail
            </NavBtnLink>
          </NavBtn> */}
          <LanguageSwitcher
            onMouseEnter={openSelector}
            onMouseLeave={closeSelector}
          >
            <LanguageWrapper>
              <img
                src={french ? fr : en}
                alt={french ? "fr" : "en"}
                width="30"
                height="30"
              />
              <LanguageChoice
                src={french ? en : fr}
                alt={french ? "en" : "fr"}
                width="30"
                height="30"
                onClick={toggleLanguage}
                showMenu={showMenu}
              />
            </LanguageWrapper>
          </LanguageSwitcher>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default NavbarWeb;
