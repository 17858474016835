import styled from "styled-components";

export const ExposesContainer = styled.div`
  display: flex;
  flex-direction: column;
  color: white;
`;

export const ExposesWrapper = styled.div`
  width: 100%;
  max-width: 1100px;
  align-self: center;
  margin-top: 50px;
`;

export const ExposesItemsWrapper = styled.dl``;

export const ExposesDate = styled.dt`
  font-weight: bold;

  @media screen and (min-width: 768px) {
    float: left;
    clear: left;
    width: 17%;
    text-align: right;
    padding-right: 10px;
  }
`;

export const ExposesContent = styled.dd`
  margin-bottom: 15px;
  @media screen and (min-width: 768px) {
    margin-left: 17%;
  }
`;

export const IntroWrapper = styled.div`
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
`;

export const BoutonPlus = styled.span`
  font-weight: bold;
  display: flex;
  align-items: center;

  &:hover {
    text-decoration: underline;
  }
`;
