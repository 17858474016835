import styled from "styled-components";

export const TagWrapper = styled.div`
  margin-bottom: 10px;
`;

export const TagTitle = styled.span`
  color: white;
  font-family: "Poppins";
  font-weight: bold;
  font-size: 16px;

  &:hover {
    text-decoration: ${({ effect }) => (effect ? "underline" : "none")};
  }
`;

export const TagItem = styled.button`
  color: ${({ active }) => (active ? "black" : "white")};
  background-color: ${({ active }) => (active ? "white" : "transparent")};

  margin-left: 10px;
  cursor: pointer;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: normal;
  font-size: 15px;

  border: 1px solid white;
  border-radius: 15px;
  padding: 8px;
  margin-top: 5px;
  display: ${({ visible }) => (visible ? "inline" : "none")};

  &:hover {
    transform: scale(1.1);
  }
`;

export const BoutonPlus = styled.span`
  font-size: 20px;
  padding-left: 5px;
`;

export const VisibleSpan = styled.span`
  display: ${({ visible }) => (visible ? "" : "none")};
`;
